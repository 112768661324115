import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Resources from "./Resources";
import Services from "./Services";
import Podcasts from "./Podcasts";
import Questions from "./Questions";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="resources" element={<Resources />} />
        <Route path="services" element={<Services />} />
        <Route path="podcasts" element={<Podcasts />} />
        <Route path="questions" element={<Questions />} />
      </Route>
    </Routes>
  );
};

export default App;
