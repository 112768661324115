import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "lucide-react";

export const Navigation: React.FC = () => (
  <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
    <div className="container">
      <Link className="navbar-brand fw-bold text-primary" to="/">
        The Maths and Science Tutor
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/#about">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/services">
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/#experience">
              Experience
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/resources">
              Resources
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/questions">
              Questions
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/podcasts">
              Podcasts
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/#contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);
