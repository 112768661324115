import React from "react";
import { Hero, Features, About, Contact } from "./components";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Features />
      <About />
      <Contact />
    </>
  );
};

export default Home;
