import React, { useState } from "react";
import {
  Book,
  FileText,
  Video,
  Download,
  ChevronDown,
  ExternalLink,
} from "lucide-react";

interface Resource {
  id: number;
  title: string;
  description: string;
  type: "study-guide" | "practice-test" | "video";
  subject: "maths" | "physics" | "chemistry" | "biology";
  level: "GCSE" | "A-Level";
  url?: string;
}

const Resources: React.FC = () => {
  const [selectedSubject, setSelectedSubject] = useState<string>("all");
  const [selectedLevel, setSelectedLevel] = useState<string>("all");

  const resources: Resource[] = [
    {
      id: 1,
      title: "A-Level Chemistry Organic Synthesis Guide",
      description:
        "Comprehensive guide covering reaction mechanisms and synthesis routes",
      type: "study-guide",
      subject: "chemistry",
      level: "A-Level",
      url: "#",
    },
    {
      id: 2,
      title: "GCSE Physics Forces Practice Questions",
      description:
        "Practice questions with detailed solutions for Forces and Motion",
      type: "practice-test",
      subject: "physics",
      level: "GCSE",
      url: "#",
    },
    {
      id: 3,
      title: "A-Level Mathematics Integration Techniques",
      description: "Advanced integration methods with worked examples",
      type: "study-guide",
      subject: "maths",
      level: "A-Level",
      url: "#",
    },
    {
      id: 4,
      title: "GCSE Biology Cell Biology Review",
      description: "Complete review of cell structure and function",
      type: "study-guide",
      subject: "biology",
      level: "GCSE",
      url: "#",
    },
  ];

  const filteredResources = resources.filter((resource) => {
    if (selectedSubject !== "all" && resource.subject !== selectedSubject)
      return false;
    if (selectedLevel !== "all" && resource.level !== selectedLevel)
      return false;
    return true;
  });

  const getIcon = (type: string) => {
    switch (type) {
      case "study-guide":
        return <Book className="text-primary" size={24} />;
      case "practice-test":
        return <FileText className="text-primary" size={24} />;
      case "video":
        return <Video className="text-primary" size={24} />;
      default:
        return <FileText className="text-primary" size={24} />;
    }
  };

  return (
    <div className="min-vh-100 bg-light py-5">
      <div className="container">
        {/* Header */}
        <div className="text-center mb-5">
          <h1 className="display-5 fw-bold mb-3">Educational Resources</h1>
          <p className="lead text-muted">
            Access curated study materials, practice tests, and guides to
            support your learning journey
          </p>
        </div>

        {/* Filters */}
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <select
              className="form-select"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
              aria-label="Filter by subject"
            >
              <option value="all">All Subjects</option>
              <option value="maths">Mathematics</option>
              <option value="physics">Physics</option>
              <option value="chemistry">Chemistry</option>
              <option value="biology">Biology</option>
            </select>
          </div>
          <div className="col-md-6">
            <select
              className="form-select"
              value={selectedLevel}
              onChange={(e) => setSelectedLevel(e.target.value)}
              aria-label="Filter by level"
            >
              <option value="all">All Levels</option>
              <option value="GCSE">GCSE</option>
              <option value="A-Level">A-Level</option>
            </select>
          </div>
        </div>

        {/* Resources Grid */}
        <div className="row g-4">
          {filteredResources.map((resource) => (
            <div key={resource.id} className="col-md-6 col-lg-4">
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body">
                  <div className="d-flex align-items-center mb-3">
                    {getIcon(resource.type)}
                    <span className="badge bg-primary ms-2">
                      {resource.level}
                    </span>
                  </div>
                  <h3 className="h5 card-title mb-3">{resource.title}</h3>
                  <p className="card-text text-muted mb-3">
                    {resource.description}
                  </p>
                  <a
                    href={resource.url}
                    className="btn btn-outline-primary d-flex align-items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Access Resource
                    <ExternalLink size={18} className="ms-2" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {filteredResources.length === 0 && (
          <div className="text-center py-5">
            <p className="text-muted">
              No resources found matching your filters.
            </p>
          </div>
        )}

        {/* Additional Resources Section */}
        <div className="mt-5 text-center">
          <h2 className="h4 mb-4">Need More Resources?</h2>
          <p className="text-muted mb-4">
            Contact me for personalized study materials or to request specific
            topics
          </p>
          <a href="/#contact" className="btn btn-primary">
            Get in Touch
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resources;
