import React from "react";
import { Calendar, Mail, Phone } from "lucide-react";

export const Contact: React.FC = () => (
  <div id="contact" className="py-5 bg-light">
    <div className="container">
      <div className="text-center mb-5">
        <h2 className="display-6 mb-3">Get in Touch</h2>
        <p className="text-muted">
          Ready to start your journey towards academic success?
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="text-center">
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <Calendar className="text-primary me-2" size={24} />
                  <span className="text-muted">
                    Available for both online and in-person sessions
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <Mail className="text-primary me-2" size={24} />
                  <a
                    href="mailto:fiaraziqbal@googlemail.com"
                    className="text-decoration-none"
                  >
                    fiaraziqbal@googlemail.com
                  </a>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <Phone className="text-primary me-2" size={24} />
                  <a href="tel:+447760257814" className="text-decoration-none">
                    07760 257 814
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
