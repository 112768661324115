import React from "react";
import { BookOpen, Users, Award } from "lucide-react";

export const Features: React.FC = () => (
  <div className="py-5 bg-light">
    <div className="container">
      <div className="row g-4">
        <div className="col-md-4">
          <div className="card h-100 text-center border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="mb-3">
                <BookOpen size={48} className="text-primary" />
              </div>
              <h3 className="h5 mb-3">Personalised Learning</h3>
              <p className="text-muted">
                Tailored tutoring plans designed to match your learning style
                and goals
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card h-100 text-center border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="mb-3">
                <Users size={48} className="text-primary" />
              </div>
              <h3 className="h5 mb-3">Expert Guidance</h3>
              <p className="text-muted">
                30+ years of teaching experience in Mathematics and Sciences
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card h-100 text-center border-0 shadow-sm">
            <div className="card-body p-4">
              <div className="mb-3">
                <Award size={48} className="text-primary" />
              </div>
              <h3 className="h5 mb-3">Proven Results</h3>
              <p className="text-muted">
                Track record of helping students achieve top grades and
                university admissions, in 2024 one of my students achieved
                269/270 in A level chemistry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
