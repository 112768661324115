import React, { useEffect, useState } from "react";

interface Question {
  id: number;
  question_text: string;
  subject: string;
  topic: string;
  difficulty: string;
  explanation: string;
  mark_scheme: string[];
  image?: {
    description: string;
    source: string;
  };
}

const Questions: React.FC = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [subjects, setSubjects] = useState<string[]>([]);
  const [difficulties, setDifficulties] = useState<string[]>([]);
  const [selectedSubject, setSelectedSubject] = useState<string>("");
  const [selectedDifficulty, setSelectedDifficulty] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetch("/api/questions");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = (await response.json()) as Question[];
        setQuestions(data);

        // Extract unique subjects and difficulties
        const uniqueSubjects = Array.from(new Set(data.map((q) => q.subject)));
        const uniqueDifficulties = Array.from(
          new Set(data.map((q) => q.difficulty))
        );

        setSubjects(uniqueSubjects);
        setDifficulties(uniqueDifficulties);
      } catch (error) {
        console.error("Error fetching questions:", error);
        setError("Failed to load questions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const filteredQuestions = questions.filter((question) => {
    const matchesSubject =
      !selectedSubject || question.subject === selectedSubject;
    const matchesDifficulty =
      !selectedDifficulty || question.difficulty === selectedDifficulty;
    return matchesSubject && matchesDifficulty;
  });

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="text-lg">Loading questions...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="text-lg text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">
        Exam Questions Database
      </h1>

      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <div className="flex flex-wrap gap-4">
          <div className="flex-1 min-w-[200px]">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Subject:
            </label>
            <select
              className="w-full border rounded-md p-2"
              value={selectedSubject}
              onChange={(e) => setSelectedSubject(e.target.value)}
            >
              <option value="">All Subjects</option>
              {subjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>

          <div className="flex-1 min-w-[200px]">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Difficulty:
            </label>
            <select
              className="w-full border rounded-md p-2"
              value={selectedDifficulty}
              onChange={(e) => setSelectedDifficulty(e.target.value)}
            >
              <option value="">All Difficulties</option>
              {difficulties.map((difficulty) => (
                <option key={difficulty} value={difficulty}>
                  {difficulty}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="space-y-6">
        {filteredQuestions.map((question) => (
          <div key={question.id} className="bg-white rounded-lg shadow p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <span className="font-bold text-blue-800">
                  {question.subject}
                </span>
                <span className="text-gray-600"> - {question.topic}</span>
              </div>
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium
                ${
                  question.difficulty === "Easy"
                    ? "bg-green-100 text-green-800"
                    : ""
                }
                ${
                  question.difficulty === "Medium"
                    ? "bg-yellow-100 text-yellow-800"
                    : ""
                }
                ${
                  question.difficulty === "Hard"
                    ? "bg-red-100 text-red-800"
                    : ""
                }
              `}
              >
                {question.difficulty}
              </span>
            </div>

            <div className="text-lg mb-4">{question.question_text}</div>

            {question.image && (
              <div className="mb-4">
                <img
                  src={question.image.source}
                  alt={question.image.description}
                  className="max-w-full h-auto rounded-lg"
                />
              </div>
            )}

            <div className="bg-gray-50 rounded-lg p-4 mb-4">
              <h3 className="font-bold mb-2">Mark Scheme:</h3>
              <ul className="list-disc pl-5 space-y-1">
                {question.mark_scheme.map((criteria, index) => (
                  <li key={index}>{criteria}</li>
                ))}
              </ul>
            </div>

            <div className="text-gray-600 italic border-t pt-4">
              <strong>Explanation:</strong> {question.explanation}
            </div>
          </div>
        ))}

        {filteredQuestions.length === 0 && (
          <div className="text-center text-gray-600">
            No questions found matching the selected criteria.
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
