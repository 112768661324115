import React from "react";

export const ContactInfo: React.FC = () => {
  return (
    <div className="bg-light border-bottom">
      <div className="container d-flex justify-content-end py-1">
        <a
          href="mailto:fiaraziqbal@googlemail.com"
          className="text-dark text-decoration-none me-4"
        >
          <i className="bi bi-envelope-fill me-1"></i>
          fiaraziqbal@googlemail.com
        </a>
        <a href="tel:07760257814" className="text-dark text-decoration-none">
          <i className="bi bi-telephone-fill me-1"></i>
          07760257814
        </a>
      </div>
    </div>
  );
};
