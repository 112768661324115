import React from "react";

export const Hero: React.FC = () => (
  <div className="bg-primary text-white py-5">
    <div className="container">
      <div className="row py-5 text-center">
        <div className="col-lg-8 mx-auto">
          <h1 className="display-4 fw-bold mb-4">
            Expert Mathematics & Science Tutoring
          </h1>
          <p className="lead mb-4">
            Empowering GCSE and A-Level students to achieve academic excellence
          </p>
          <a
            href="https://calendly.com/fiaraz/assessment"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-light btn-lg px-4"
            aria-label="Schedule an assessment session"
          >
            Book a Session
          </a>
        </div>
      </div>
    </div>
  </div>
);
