import React from "react";
import {
  Book,
  Clock,
  Users,
  GraduationCap,
  Calculator,
  Beaker,
} from "lucide-react";

const Services: React.FC = () => {
  const services = [
    {
      icon: <Calculator size={40} />,
      title: "Mathematics",
      description:
        "GCSE and A-Level Mathematics tutoring covering all exam boards including Edexcel, AQA, and OCR.",
      highlights: ["Pure Mathematics", "Statistics", "Mechanics"],
    },
    {
      icon: <Beaker size={40} />,
      title: "Sciences",
      description:
        "Comprehensive science tutoring at both GCSE and A-Level, covering Physics, Chemistry, and Biology.",
      highlights: ["Physics", "Chemistry", "Biology"],
    },
    {
      icon: <GraduationCap size={40} />,
      title: "Exam Preparation",
      description:
        "Focused exam preparation including past paper practice, revision techniques, and exam strategies.",
      highlights: ["Past Papers", "Mark Schemes", "Examiner Reports"],
    },
  ];

  return (
    <div className="py-5">
      {/* Hero Section */}
      <div className="bg-primary text-white py-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h1 className="display-4 fw-bold">Our Services</h1>
              <p className="lead">
                Expert tutoring services tailored to your academic needs
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="container">
        <div className="row g-4">
          {services.map((service, index) => (
            <div key={index} className="col-lg-4">
              <div className="card h-100 border-0 shadow-sm">
                <div className="card-body p-4">
                  <div className="text-primary mb-3">{service.icon}</div>
                  <h3 className="h4 mb-3">{service.title}</h3>
                  <p className="text-muted mb-4">{service.description}</p>
                  <ul className="list-unstyled">
                    {service.highlights.map((highlight, idx) => (
                      <li key={idx} className="mb-2 d-flex align-items-center">
                        <Book size={16} className="text-primary me-2" />
                        {highlight}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Information */}
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                <h3 className="h4 mb-4 text-center">
                  Why Choose Our Services?
                </h3>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="d-flex align-items-start mb-3">
                      <Clock className="text-primary me-3" size={24} />
                      <div>
                        <h4 className="h5">Flexible Scheduling</h4>
                        <p className="text-muted mb-0">
                          Online and in-person sessions available at times that
                          suit you
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-start">
                      <Users className="text-primary me-3" size={24} />
                      <div>
                        <h4 className="h5">Personalized Approach</h4>
                        <p className="text-muted mb-0">
                          Tailored learning plans designed for your specific
                          needs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-5">
          <h3 className="h4 mb-4">Ready to Get Started?</h3>
          <a
            href="https://calendly.com/fiaraz/assessment"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-lg"
          >
            Book a Free Consultation
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
