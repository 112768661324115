import React from "react";

export const Footer: React.FC = () => (
  <footer className="bg-dark text-white py-4">
    <div className="container">
      <div className="text-center">
        <p className="mb-0 text-muted">
          © 2024 The Maths and Science Tutor. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);
