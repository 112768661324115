import React, { useEffect, useState } from "react";

interface Podcast {
  name: string;
  description: string;
  url: string;
}

const Podcasts: React.FC = () => {
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [currentPodcast, setCurrentPodcast] = useState<Podcast | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        setLoading(true);
        const response = await fetch("/api/podcasts");
        const data = await response.json();
        setPodcasts(data);
      } catch (err) {
        setError("Failed to load podcasts. Please try again later.");
        console.error("Error fetching podcasts:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchPodcasts();
  }, []);

  if (loading) {
    return (
      <div className="container py-5">
        <div className="text-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container py-5">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="container py-5">
      <h1 className="text-primary mb-4">Educational Podcasts</h1>

      {/* Current Playing Podcast */}
      {currentPodcast && (
        <div className="card mb-4 p-3">
          <h5 className="card-title">{currentPodcast.description}</h5>
          <audio
            controls
            className="w-100 mt-2"
            src={currentPodcast.url}
            autoPlay
          >
            Your browser does not support the audio element.
          </audio>
        </div>
      )}

      {/* Podcast List */}
      <div className="row g-4">
        {podcasts.map((podcast) => (
          <div key={podcast.name} className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">{podcast.description}</h5>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setCurrentPodcast(podcast)}
                  >
                    {currentPodcast?.name === podcast.name
                      ? "Now Playing"
                      : "Play"}
                  </button>
                  <small className="text-muted">Educational Content</small>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {podcasts.length === 0 && (
        <div className="alert alert-info">
          No podcasts available at the moment. Please add .wav files to the
          public/podcasts directory.
        </div>
      )}

      <div className="mt-5">
        <h2 className="h4 mb-3">About Our Educational Podcasts</h2>
        <p>
          Our educational podcasts are designed to help students understand
          complex mathematical and scientific concepts through clear audio
          explanations. Each podcast focuses on a specific topic and provides
          detailed insights and examples.
        </p>
        <div className="alert alert-info mt-3">
          <h3 className="h5">How to Add Podcasts</h3>
          <p className="mb-0">
            Email your podcast to fiaraziqbal@googlemail.com. The filename
            should describe the content, using underscores between words (e.g.,
            "algebra_basics.wav" or "solving_quadratic_equations.wav").
          </p>
        </div>
      </div>
    </div>
  );
};

export default Podcasts;
