import React from "react";
import { Award } from "lucide-react";

export const About: React.FC = () => (
  <div id="about" className="py-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <h2 className="display-6 mb-4">About Me</h2>
          <p className="text-muted mb-4">
            As an educator with over thirty years of experience, including
            serving as a Headteacher at a successful international school, I
            understand the transformative power of dedicated tutoring.
          </p>
          <p className="text-muted">
            My approach focuses on building confidence, improving exam
            technique, and developing effective study strategies. I specialize
            in preparing students for GCSE and A-Level examinations in
            Mathematics and Sciences.
          </p>
        </div>
        <div className="col-lg-6">
          <div className="card border-0 bg-light">
            <div className="card-body p-4">
              <h3 className="h5 mb-4">Specializations</h3>
              <ul className="list-unstyled mb-0">
                <li className="d-flex align-items-center mb-3">
                  <Award className="text-primary me-2" size={20} />
                  <span>GCSE Mathematics and Sciences</span>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <Award className="text-primary me-2" size={20} />
                  <span>A-Level Physics, Chemistry, and Biology</span>
                </li>
                <li className="d-flex align-items-center">
                  <Award className="text-primary me-2" size={20} />
                  <span>University Entrance Preparation</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
